
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '../../mixins';

@Component
export default class BinocularsSvg extends Mixins(VuetifyMixin){
	@Prop({ default: 24 }) private size: number;
	@Prop({ default: '#BDBDBD' }) private color: string;
	get Color(): string{
		return this.getColor(this.color);
	}
}
