
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiPlus, mdiMagnify, mdiContentPaste, mdiPlusCircleOutline, mdiSchool, mdiSchoolOutline, mdiBook } from '@mdi/js';
import SideNavSectionTitle from './SideNavSectionTitle.vue';
import BinocularsSvg from '@/components/svg/BinocularsSvg.vue';
import BestAthletesLogo from '../ui/BestAthletesLogo.vue';
import CircledAvatar from '../ui/CircledAvatar.vue';
import { hasRole } from '@/directives/v-require-role';
import { AuthMixin, MyStudentCoursesMixin, MyInstructorCoursesMixin, MyTeamsMixin, VuetifyMixin } from '../../mixins';
import { RoleName } from '../../../types/enums';
import * as Routes from '../../../types/constants/web_client_user.routes';
import { Route } from 'vue-router';

@Component({
	components: { BestAthletesLogo, BinocularsSvg, CircledAvatar, SideNavSectionTitle },
})
export default class CoachSideNav extends Mixins(AuthMixin, MyStudentCoursesMixin, MyInstructorCoursesMixin, MyTeamsMixin, VuetifyMixin){
	mini = false;
	mdiBook = mdiBook;
	mdiPlus = mdiPlus;
	mdiPlusCircleOutline = mdiPlusCircleOutline;
	mdiMagnify = mdiMagnify;
	mdiSchool = mdiSchool;
	mdiSchoolOutline = mdiSchoolOutline;
	mdiContentPaste = mdiContentPaste;

	get IsInstructorAdmin(): boolean{
		return this.InstructorAdminRoles.map(role => hasRole(role)).reduce((a,b) => a && b, true);
	}

	get CreateTeamRoutes(): Partial<Route>{
		return {
			name: Routes.TeamCreate
		}
	}

	get TeamDashboardRoute(): string{
		return Routes.TeamDashboard;
	}
	get RecruitingRoute(): string{
		return Routes.CoachRecruitingSummary;
	}
	get ScoutingRoute(): string{
		return Routes.CoachScoutingSummary;
	}
	get InstructorCourseRoute(): string{
		return Routes.CourseDashboard;
	}
	get StudentCourseRoute(): string{
		return Routes.StudentCoursePage;
	}

	@Prop({ default: true, type: Boolean }) value: boolean;
	/**
	* The router's authGuard prevents the app from loading before auth0 is ready but the SideNav is outside of the app's router-view
	* So we watch the auth instance's user property and do some setup once it stops being null.
	*/
	userProfileUnwatch: () => void;
	userProfileReady(): void{
		if(this.userProfileUnwatch) this.userProfileUnwatch();
		this.initMyTeams();
		this.initMyCourses();
		this.initMyStudentCourses();
	}
	created(): void{
		this.userProfileUnwatch = this.$auth.$watch("$auth.user", user => {
			if(user !== null){
				this.userProfileReady();
			}
		});
	}

	get ShowTeams(): boolean{
		return true;
	}
	get ShowAthletes(): boolean{
		return true;
	}

	get IsStudent(): boolean{
		return this.MyStudentCourses.length > 0;
	}
	get IsInstructor(): boolean{
		return this.MyCoursesSideNav.length > 0;
	}
	get InstructorAdminRoles(): RoleName[]{
		return [ RoleName.InstructorAdmin, RoleName.Admin ];
	}

}
