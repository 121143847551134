
import Vue from 'vue';
import { mdiMenu } from '@mdi/js';
import CoachSideNav from '../components/navigation/CoachSideNav.vue';
import AppBar from '../components/navigation/AppBar.vue';
import Footer from '../components/navigation/Footer.vue';
export default Vue.extend({
	components: { AppBar, CoachSideNav, Footer },
	data(){
		return {
			mdiMenu,
			showSideNav: true,
		}
	},
	created(){
		if(this.CollapseSideNav) this.showSideNav = false;
	},
	computed:{
		CollapseSideNav(){
			return this.$vuetify.breakpoint.mobile;
		},
		ShowSystemBar(): boolean{
			return this.$slots['system-bar'] !== undefined;
		}
	}
});
